import React, { useEffect } from 'react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import { GAT_DATA, POST_DATA } from '../../global/apiType';
import { APIS_ENDPOINT, BASE_URL } from "../../global/server";
import { useNavigate } from 'react-router';

import Logo from "../../assets/images/GUSD_logo.png";

// import PropTypes from 'prop-types'


const SignIn = () => {
  // const [user, setUser] = useState({})
  const handleCallbackResponse = async (response) => {
    // console.log("Encoded JWT ID token: " + response.credential);
    let userObject = jwt_decode(response.credential);
    userObject = JSON.stringify(userObject);
    userObject = JSON.parse(userObject);

    if (userObject) {
      let userDetails = {
        useremail: userObject?.email,
        sub: userObject?.sub,
        displayName: userObject?.name
      }
      let url = BASE_URL + APIS_ENDPOINT.userGoogleCreate;
      let token = APIS_ENDPOINT.bearerToken;
      let CreateUser = await POST_DATA(url, userDetails, token);
      //---------- PERMISSION User and Role Combining Together and forward to redux ----------
      var validateAdminPermissionUrl = BASE_URL + APIS_ENDPOINT.validateAdminPermission + "/" + userObject?.email;
      var getUserSectionPermission = await GAT_DATA(validateAdminPermissionUrl, CreateUser.token);
      let rolesPermissionsUrl = BASE_URL + APIS_ENDPOINT.rolePermissions;
      let rolesPermissionsList = await GAT_DATA(rolesPermissionsUrl, CreateUser.token);
      if (rolesPermissionsList.length > 0) {
        const filteredPermissions = rolesPermissionsList.filter(permission => {
          return permission.Roles.some(role => role.RoleId === CreateUser.isGoogleUser.role_id);
        });
        const finalAllPermissions = {};
        filteredPermissions.forEach(permission => {
          const { PermissionName, Roles } = permission;
          const role = Roles.find(role => role.RoleId === CreateUser.isGoogleUser.role_id);
          finalAllPermissions[PermissionName] = role.IsAccess;
        });
        for (var i = 0; i < getUserSectionPermission.length; i++) {
          var section = getUserSectionPermission[i];
          var sectionName = section.section_name;
          finalAllPermissions[sectionName] = 1;
        }
        setItemWithExpiration('UserPermissions', finalAllPermissions, 86400)
      }
      //---------- PERMISSION end ----------
      //set Role Id to localStorage
      setItemWithExpiration('roleId', CreateUser.isGoogleUser.role_id, 86400);
      setItemWithExpiration('userId', CreateUser.isGoogleUser.id, 86400);
      if (CreateUser.token) {
        setItemWithExpiration('GUSD-user-token', CreateUser.token, 86400);
        navigate('/Dashboard')
      }
    }
    if (userObject) {
      setItemWithExpiration('GUSD-user-Google-token', response.credential, 86400)
      navigate('/Dashboard')
    }
  }

  function setItemWithExpiration(key, value, expirationSeconds) {
    const expirationTime = new Date().getTime() + (expirationSeconds * 1000);
    const item = {
      value: value,
      expirationTime: expirationTime
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  // Check if the item is expired and remove it from local storage
  function checkItemExpiration() {
    const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
    const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
    if (item && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-token');
      window.location.reload();
      window.location = '/'
    }

    if (itemGoogle && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-Google-token');
      window.location.reload();
      window.location = '/'
    }
  }

  setInterval(checkItemExpiration, 600000);

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      //Local_3000
      // client_id: "334181109408-3kqfvvup6ufubdb8d0ka1n58lnn4j29l.apps.googleusercontent.com",
      // DEV_Online
      // client_id: "285078361320-tdqutcngqgcs03681hasr536b5tgqrnk.apps.googleusercontent.com",
       //UAT_Online
       client_id: "507695196807-3n66k25ld3fv3fe7973e4rgnqdkpemq3.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large", width: document.getElementById("signInDiv")?.offsetWidth }
    );
    google.accounts.id.prompt();
    document.body.classList.add('sigin-layout')
    return () => {
      document.body.classList.remove('sigin-layout')
    }
  }, [])
  const navigate = useNavigate();

  return (
    <div className="d-lg-flex half">
      <div className="bg order-1 order-md-2 login-graphic"></div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <div className="mb-2">
                <img src={Logo} alt="avatar" className="img-fluid rounded-circle" style={{ width: 80 }} />
                <h3 className='logologin-text-1'>Welcome to GUSD</h3>
                <h2 className='logologin-text-2'>Board of Education</h2>
                <p className="mt-4 mb-0">Please enter details to Login</p>
              </div>

              <div id='signInDiv'></div>
              {/* <button type="button" className="login-with-google-btn" >
                <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=" alt="avatar" className="img-fluid rounded-circle" style={{ width: 20, marginRight: 15 }} />

                Sign in with Google
              </button> */}
              {/* <Form>
                <Form.Group className='form-group'>
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="email"
                  />
                </Form.Group>
                <Form.Group size="lg" controlId="password" className='form-group'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                  />
                </Form.Group>
                <div className='linkwithtext'><p><input type='checkbox' /> Remember me</p><a href="">Forgot Password?</a></div>
                <Button block="true" size="lg" type="submit" style={{ 'width': '100%' }} onClick={() => navigate('/Dashboard')}>
                  Login
                </Button>
              </Form> */}
              {/* <p className='linkwithtext justify-content-center' style={{'marginTop': '42px' }}>Not Registered Yet? <a href="" style={{ 'marginLeft': '10px'}}>Create account</a>.</p> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default SignIn;