/* eslint-disable no-prototype-builtins */
import { Add, Edit, Edit2, Trash } from "iconsax-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { GoKebabVertical } from "react-icons/go";
import "./reportSection.css";
import { FiRefreshCcw } from "react-icons/fi";
import { Button as PrimeButton } from 'primereact/button';
// import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Editor } from "primereact/editor";
import { ListBox } from 'primereact/listbox';
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Form from "react-bootstrap/Form";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { FiCheck } from "react-icons/fi";
import { RiDeleteBinLine, RiSave2Fill } from "react-icons/ri";
import { RxDragHandleDots2 } from "react-icons/rx";
import { TbGripHorizontal } from "react-icons/tb";
import { useNavigate } from "react-router";
import { AuthContext } from "../../components/ContextAPI/ContextAPI";
import PDFViewer from "../../components/PDFViewer";
import PDFViewerForDeny from "../../components/PdfViewerForDeny";
import { PageNames } from "../../global";
import { DASHBOARD } from "../../global/PageNames";
import { GAT_DATA, POST_DATA, POST_DATA_FORMDATA, PUT_DATA } from "../../global/apiType";
import { APIS_ENDPOINT, BASE_URL } from "../../global/server";
import 'primeicons/primeicons.css';
import TitlePDFViewer from "../../components/TitlePDFViewer";
import TitlePDFViewerForDeny from "../../components/TitlePDFViewerDeny";
import { MultiSelect } from 'primereact/multiselect';
import { getAllByAltText } from "@testing-library/react";

const ReportSection = () => {
   let userToken = JSON.parse(localStorage.getItem('GUSD-user-token'));
   const [allWidgetsApproved, setAllWidgetsApproved] = useState(false);
   const [showSaveContent, setShowSaveContent] = useState(true);
   const [showDialog, setShowDialog] = useState(false);
   const [isDisabled, setIsDisabled] = useState(false);
   const UserPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
   const UserRole = JSON.parse(localStorage.getItem('roleId'));
   const UserId = JSON.parse(localStorage.getItem('userId'));
   const { reportSectionDetails, reportCurrentSectionDetails } = useContext(AuthContext);
   let reportCurrentData = JSON.parse(localStorage.getItem("report_section_data"));
   let sectionData = JSON.parse(localStorage.getItem("section_data"));
   const navigate = useNavigate();
   const toast = useRef(null);
   //Report name title
   const [reportNameTitle, setReportNameTitle] = useState(false);
   const handleReportNameTitle = () => {
      setReportNameTitle((reportNameTitle) => !reportNameTitle);
   };

   const [getStatus, setStatus] = useState();
   //! DragDropContext and state and data
   const [currentColumns, setCurrentColumns] = useState(reportSectionDetails?.sections ?? sectionData?.sections);
   const [getSectionData, setSectionData] = useState('')
   // const handleDragEnd = (result) => {
   //    if (!result.destination) {
   //       return;
   //    }
   const handleDragEnd = (result) => {
      setLoading(true);
      if (!result.destination) {
         setLoading(false);
         return;
      }
      const draggedColumn = currentColumns[result.source.index];
      const updatedColumns = [...currentColumns];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      const updatedColumnsWithPosition = updatedColumns.map((column, index) => ({
         ...column,
         position: index,
      }));
      setCurrentColumns(updatedColumnsWithPosition, () => {
         setLoading(false);
      });
      setCurrentColumns(updatedColumnsWithPosition);
   };
   // setCurrentColumns(updatedColumnsWithPosition);
   // };
   //select Item item state and handler
   const [selectedItem, setSelectedItem] = useState(sectionData?.sections[0]?.name ?? "");
   const [selectedItemSectionId, setSelectedItemSectionId] = useState(sectionData?.sections[0]?.id ?? "");
   const [isSectionApproved, setIsSectionApproved] = useState(0);
   const [allSectionApproved, setAllSectionApproved] = useState(false);
   const [hideSectionWiseApprove, setHideSectionWiseApprove] = useState(true);
   const [showApproveButtonForReportBuilder, setShowApproveButtonForReportBuilder] = useState(1);
   const [showReportSendForApproval,setShowReportSendForApproval] = useState(false);
   const [showApprovedLabel, setShowApprovedLabel] = useState(1);
   const [isOpeningApproved, setIsOpeningApproved] = useState(0);
   const [isCreateReport, setIsCreateReport] = useState(0);
   const [section, setSection] = useState();
   const handleSelect = (item) => {
      console.log("item-->ReportTemplateSection", item.ReportTemplateSection ? 1: 0)
      setShowApproveButtonForReportBuilder(item.ReportTemplateSection && (item.id !== 1 && item.id !== 2) ? item.ReportTemplateSection.status : 1)
      console.log("item.ReportTemplateSection && (item.id !== 1 || item.id !== 2) ? item.ReportTemplateSection.status : 0", item.ReportTemplateSection && (item.id !== 1 || item.id !== 2) ? item.ReportTemplateSection.status : 0)
      setShowApprovedLabel(item.ReportTemplateSection ? 1 : 0);

      // setting 1 for handling Approve Button
      setIsOpeningApproved(1);
      setSection(item)
      if (item.id !== 2 && item.id !== 1) {
         if (item.wedgets.length > 0) { setHideSectionWiseApprove(true) } else { setHideSectionWiseApprove(false) }
      } else {
         if (item.wedgets.length > 0) { setHideSectionWiseApprove(true) } else { setHideSectionWiseApprove(true) }
      }
      setIsSectionApproved(0)
      setSelectedItemSectionId(item.id);
      setSelectedItem(item.name);
      if (item.ReportTemplateSection) {
         if (item.wedgets.length) {
            let getStatus = item.wedgets.every(a => a.status == 1)
            setIsSectionApproved(getStatus)
         } else {
            setIsSectionApproved(item.ReportTemplateSection.status)
         }
      }
   };
   const [reportName, setReportName] = useState(reportSectionDetails?.name ?? sectionData?.name);
   const [optionStatevalue, setOptionStatevalue] = useState(['']);
   //! Agenda Cover State and handler
   //FOR NAME AND ADDRESS
   const [nameAC, setNameAC] = useState("");
   const [addressAC, setAddressAC] = useState("");
   //FOR TITLE
   const [titleAC, setTitleAC] = useState("");
   const handleTitle = (event) => {
      setTitleAC(event.target.value);
   };
   //MEETING TYPE
   const [meetingTypeAC, setMeetingTypeAC] = useState("");
   const handleMeetingTypeAC = (event) => {
      setMeetingTypeAC(event.target.value);
   };

   //FOR DATE
   const [dateAC, setDateAC] = useState("");
   const handleDateAC = (event) => {
      setDateAC(event.target.value);
   };
   //FOR MEETING NUMBER
   const [meetingNumberAC, setMeetingNumberAC] = useState("");
   const handleMeetingNumberAC = (event) => {
      setMeetingNumberAC(event.target.value);
   };

   //FOR IMAGE COVER
   const [imageCoverAC, setImageCoverAC] = useState({ key: "", image: "" });
   const [imageCoverLocalAC, setImageCoverLocalAC] = useState("./logo192.png");
   const handleImageCoverAC = async (event) => {
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = APIS_ENDPOINT.uploadImageFile;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
         let getImageUrl = await POST_DATA_FORMDATA(url, formData, token);
         setImageCoverAC({ key: getImageUrl.key, image: getImageUrl.attachment });
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log("error: ", error);
      }
      setImageCoverLocalAC(URL.createObjectURL(event.target.files[0]));
   };
   //! end Agenda Cover State and handler

   //!Agenda State and handler
   //FOR NAME AND ADDRESS
   const [nameAndAddressAgenda, setNameAndAddressAgenda] = useState("");

   //FOR TITLE
   const [titleA, setTitleA] = useState("");
   const handleTitleA = (event) => {
      setTitleA(event.target.value);
   };
   //! end Agenda State and handler

   // Setup Agenda cancle and save
   // const handleCancelSetupAgenda = () => {
   // };

   // sideBar state and handler for setup agenda
   const [showSetupTable, setShowSetupTable] = useState(false);
   const [sendTime, setSendTime] = useState(false);

   const [setupAgenda, setSetupAgenda] = useState(false);

   const handleSetupAgenda = () => {
      setSendTime(false);
      setSetupAgenda(true);
      //   setShowSetupTable(false);
   };
   const ReportSection = () => {
      setDetailedDescription("");
      setAttachments("");
      setVisibleFrom((newValue) => !newValue);
      setPriorities([]);
   };

   const PRIORITY_ARRAY = [{ name: 'Maximize Student Achievement', code: '1' }, { name: 'Foster a Positive Culture of Learning', code: '2' }, { name: 'Ensure the Health and Safety of Students and Employees', code: '3' }, { name: 'Maintain District Financial Responsibility', code: '4' }];

   const handleSaveSetupAgenda = () => {
      setSetupAgenda((newValue) => !newValue);
      setSendTime(true);
      setShowSetupTable(true);
   };

   const onChangePriorityDropdown = (e) => {
      setPriorities(e.value);

      let priorityArray = e.value;

      let str = "";
      priorityArray.map((item, i) => {
         if (i == 0) {
            str = item.code;
         } else {
            str = str + ',' + item.code;
         }
      })

      setBoardPriorities(str);

   }

   // const handleAttachments = async (event) => {
   //    if (event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
   //       toast.current.show({
   //          severity: "error",
   //          summary: "Only .docx files allowded",
   //          life: 3000,
   //       });
   //    }

   // sideBar state and handler
   const [visibleFrom, setVisibleFrom] = useState(false);
   const handleVisibleFrom = (UserRole, getStatus) => {
      // if (UserRole === 2 && getStatus === 0)
      // {
      //    setStatus(true);
      // }
      setPreviewReportTitle("");
      setTo("Board of Education");
      seFrom("Dr. Vivian Ekchian, Superintendent");
      setPreparedBy("");
      setsubmittedBy("");
      setSubject("");
      setShortDescription("");
      setDetailedDescription("");
      setAttachments("");
      setVisibleFrom((newValue) => !newValue);
      setPriorities([]);
   };

   const [previewReportTitle, setPreviewReportTitle] = useState("");
   const [shortDescription, setShortDescription] = useState("");
   const [detailedDescription, setDetailedDescription] = useState("");
   //adding
   const [to, setTo] = useState("Board of Education");
   const [from, seFrom] = useState("Dr. Vivian Ekchian, Superintendent");
   const [preparedBy, setPreparedBy] = useState("");
   const [submittedBy, setsubmittedBy] = useState("");
   const [subject, setSubject] = useState("");
   const [boardPriorities, setBoardPriorities] = useState("");
   const [priorities, setPriorities] = useState([]);
   const [itemId, setItemId] = useState();
   const [reportTemplate, setReportTemplate] = useState([]);
   const [attachments, setAttachments] = useState({
      name: "",
      key: "",
      attachments: "",
      fileContent: "",
   });
   const handleAttachments = async (event) => {
      setShowSaveContent(false)
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = `${BASE_URL}/gusd/file`;
      const file = event.target.files[0];
      const maxSize = 1000000;
      // if(file && file.size > maxSize){
      //    alert('File size exceeds the maximum allowed size (1 MB).')
      // }
      // else{
      //    console.log('error')
      // }
      const formData = new FormData();
      formData.append("file", file);
      try {
         let getImageUrl = await POST_DATA_FORMDATA(url, formData, token);
         if (getImageUrl) {
            setAttachments({
               name: file.name,
               key: getImageUrl.key,
               fileContent: getImageUrl.html,
               attachments: getImageUrl.attachment,
            });
         }
         setShowSaveContent(true)
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log("error: ", error);
         setShowSaveContent(true)
      }
   };
   // end sideBar state and handler

   //Opening Report Section data
   const [opening, setOpenings] = useState([]);
   const handleDragOpenings = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = opening[result.source.index];
      const updatedColumns = [...opening];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setOpenings(updatedColumns);
   };
   //Acknowledgements and Recognition
   const [acknowledgmentsAndRecordings, setAcknowledgmentsAndRecordings] = useState([]);
   const handleDragAcknowledgmentsAndRecordings = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = acknowledgmentsAndRecordings[result.source.index];
      const updatedColumns = [...acknowledgmentsAndRecordings];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setAcknowledgmentsAndRecordings(updatedColumns);
   };
   //Presentation
   const [presentation, setPresentation] = useState([]);
   const handleDragPresentation = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = presentation[result.source.index];
      const updatedColumns = [...presentation];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setPresentation(updatedColumns);
   };

   //Student Board Member Report  Student Board Memeber Report
   const [studentAndMember, setStudentAndMember] = useState([]);
   const handleStudentAndMember = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = studentAndMember[result.source.index];
      const updatedColumns = [...studentAndMember];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setStudentAndMember(updatedColumns);
   };
   // Communications from the Public
   const [publicCommunication, setPublicCommunication] = useState([]);
   const handlePublicCommunication = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = publicCommunication[result.source.index];
      const updatedColumns = [...publicCommunication];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setPublicCommunication(updatedColumns);
   };
   //Information
   const [informationSection, setInformationSection] = useState([]);
   const handleDragInformationSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = informationSection[result.source.index];
      const updatedColumns = [...informationSection];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setInformationSection(updatedColumns);
   };
   //Action
   const [actionSection, setActionSection] = useState([]);
   const handleDragActionSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = actionSection[result.source.index];
      const updatedColumns = [...actionSection];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setActionSection(updatedColumns);
   };
   //Consent Section
   const [consent, setConsentSection] = useState([]);
   const handleDragConsentSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = consent[result.source.index];
      const updatedColumns = [...consent];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setConsentSection(updatedColumns);
   };

   // Reports and Correspondence
   const [reportsAndCorrespondence, setReportsAndCorrespondence] = useState([]);
   const handleDragReportsAndCorrespondence = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = reportsAndCorrespondence[result.source.index];
      const updatedColumns = [...reportsAndCorrespondence];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setReportsAndCorrespondence(updatedColumns);
   };

   //Adjournment
   const [adjournment, setAdjournment] = useState([]);
   const handleDragAdjournment = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = adjournment[result.source.index];
      const updatedColumns = [...adjournment];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setAdjournment(updatedColumns);
   };

   const [editWidget, setEditWidget] = useState(false);
   const [editWidgetId, setEditWidgetId] = useState();
   // Handle Delete
   const handleDelete = (index) => {
      confirmDialog({
         message: 'Do you want to delete this Section?',
         header: 'Confirmation',
         icon: 'pi pi-info-circle',
         acceptClassName: 'p-button p-component p-confirm-dialog-accept',
         accept: () => {// console.log("Deleting section at index", index);
            const updatedTemplate = [...currentColumns];
            updatedTemplate.splice(index, 1);
            // console.log("Updated Template after delete",updatedTemplate);
            setCurrentColumns([...updatedTemplate]);
         }
      });
   };

   const handleEdit = (item) => {
      let { id, name, WidgetAttribute } = item;
      setEditWidgetId(id);
      setEditWidget(true);
      setVisibleFrom(true);
      setPreviewReportTitle(name);
      setTo(WidgetAttribute.to);
      seFrom(WidgetAttribute.from);
      setPreparedBy(WidgetAttribute.preparedBy);
      setsubmittedBy(WidgetAttribute.submittedBy);
      setSubject(WidgetAttribute.subject);
      setShortDescription(WidgetAttribute.shortDescription);

      let priorities = WidgetAttribute.selectBoardProperties ? WidgetAttribute.selectBoardProperties.split(",") : [];

      let priorityData = [];

      // PRIORITY_ARRAY

      for (let i = 0; i < priorities.length; i++) {
         let obj = PRIORITY_ARRAY.find(o => o.code === priorities[i]);
         priorityData.push(obj);
      }

      setBoardPriorities(WidgetAttribute.selectBoardProperties);
      setPriorities(priorityData);

      // console.log("detailedDescriptiondetailedDescription 2:-",WidgetAttribute.detailedDescription);
      let des = (WidgetAttribute.detailedDescription).replace(/ /g, "&nbsp;")
      console.log("des:-",des);

      setDetailedDescription(des);

      if (WidgetAttribute.attachment != null) {
         setAttachments({
            fileContent: WidgetAttribute.fileContent,
            attachments: WidgetAttribute.attachment,
         });
      }
   };

   const handleSaveSectionData = () => {
      const sections = {
         Opening: { state: opening, setState: setOpenings },
         "Acknowledgements and Recognition": {
            state: acknowledgmentsAndRecordings,
            setState: setAcknowledgmentsAndRecordings,
         },
         Presentation: { state: presentation, setState: setPresentation },
         "Student Board Member Report": {
            state: studentAndMember,
            setState: setStudentAndMember,
         },
         "Communications from the Public": {
            state: publicCommunication,
            setState: setPublicCommunication,
         },
         "Information": {
            state: informationSection,
            setState: setInformationSection,
         },
         "Action": { state: actionSection, setState: setActionSection },
         "Consent": { state: consent, setState: setConsentSection },
         "Reports and Correspondence": {
            state: reportsAndCorrespondence,
            setState: setReportsAndCorrespondence,
         },
         Adjournment: { state: adjournment, setState: setAdjournment },
      };

      const { state, setState } = sections[selectedItem];

      if (Array.isArray(sections) && sections[selectedItem]) {
         const { state, setState } = sections[selectedItem];
      } else {
         console.log('error')
      }


      if (previewReportTitle != "") {
         // Generate a unique ID
         const generateUniqueId = () => {
            let uniqueId = state.length + 1;
            while (state.find((item) => item.id === uniqueId)) {
               uniqueId++;
            }
            return uniqueId;
         };
         console.log("detailedDescription", detailedDescription)
         const formData = {
            id: generateUniqueId(),
            name: previewReportTitle,
            value: previewReportTitle,
            widget_type: 2,
            UserRole: UserRole.value,
            WidgetAttribute: {
               attributetitle: previewReportTitle,
               shortDescription: shortDescription,
               // detailedDescription: detailedDescription,
               detailedDescription: (detailedDescription).replace(/ /g, "&nbsp;"),
               attachment: attachments,
               // fileContent: attachments ? attachments.fileContent : null,
               fileContent: attachments.fileContent,
               to: to,
               from: from,
               preparedBy: preparedBy,
               submittedBy: submittedBy,
               subject: subject,
               selectBoardProperties: boardPriorities,
            },
         };
         setSectionData(formData);
         const isDuplicate = state.some((item) => item.name === formData.name);
         if (!isDuplicate) {
            setState((current) => [...current, formData]);
         }

         setPreviewReportTitle("");
         setShortDescription("");
         setDetailedDescription("");
         setAttachments("");
         handleVisibleFrom();
         setEditWidgetId();
         setEditWidget(false);
      } else {
         toast.current.show({
            severity: "error",
            summary: "Please Fill Title field",
            life: 3000,
         });
      }
   };

   //Validation for agenda amd agenda cover
   const handleValidation = ({ status }) => {
      const validation = {
         nameAC: "Please Fill Name of Agenda cover",
         addressAC: "Please Fill Address of Agenda cover",
         titleAC: "Please Fill Title of Agenda cover",
         meetingTypeAC: "Please Fill Meeting type of Agenda cover",
         meetingNumberAC: "Please Fill Meeting Number of Agenda cover",
         dateAC: "Please Fill Date of Agenda cover",
         //Agenda
         // nameAndAddressAgenda: "Please Fill Name and Address of Agenda",
         titleA: "Please Fill address of Agenda",
         // meetingNumberA: "Please Fill Meeting Number of Agenda",
      };

      for (const property in validation) {
         if (!validation.hasOwnProperty(property)) {
            continue;
         }

         if (!eval(property) || eval(property) === "") {
            toast.current.show({
               severity: "error",
               summary: validation[property],
               life: 3000,
            });
            return false;
         }
      }
      sendToDB({ status });
   };

   //SAVE DATA INTO DB
   const [reportID, setReportID] = useState(null);
   const sendToDB = async ({ status }) => {
      // const updatedSection = getSectionData.wedgets.map((data) => {
      //    if (data.UserRole === 2 &&!data.status && !data.status_updated_by) {
      //      data.status = 1;
      //      data.status_updated_by = UserId.value;
      //    }
      //    return data;
      //  });
      if (getSectionData.UserRole === 2 || getSectionData.UserRole === 3 && !getSectionData.status && !getSectionData.status_updated_by) {
         getSectionData.status = 1;
         getSectionData.status_updated_by = UserId.value;
      }
      const updatedData = currentColumns.map((item, index) => {
         return {
            id: item.id,
            name: item.name,
            position: item.position ?? index,
            time: sendTime ? item.time : "00:00",
            widgets: [],
         };
      });

      let AgendaCover = [
         {
            id: 1,
            value: nameAC,
            widget_type: 1,
         },
         {
            id: 2,
            value: addressAC,
            widget_type: 2,
         },
         {
            id: 3,
            value: titleAC,
            widget_type: 1,
         },
         {
            id: 4,
            value: meetingTypeAC,
            widget_type: 1,
         },
         {
            id: 5,
            value: dateAC,
            widget_type: 3,
         },
         {
            id: 6,
            value: meetingNumberAC,
            widget_type: 4,
         },
         // {
         //    id: 7,
         //    value: `${imageCoverAC.key}`,
         // },
      ];
      let Agenda = [
         {
            id: 8,
            value: titleA,
            widget_type: 1,
         },
         {
            id: 9,
            value: JSON.stringify(optionStatevalue),
            widget_type: 8,
         },
      ];

      const sections = {
         1: AgendaCover,
         2: Agenda,
         3: opening,
         4: acknowledgmentsAndRecordings,
         5: presentation,
         6: studentAndMember,
         7: publicCommunication,
         8: informationSection,
         9: actionSection,
         10: consent,
         11: reportsAndCorrespondence,
         12: adjournment,
      };

      for (let i = 0; i < updatedData.length; i++) {
         const sectionId = updatedData[i].id;
         if (sections.hasOwnProperty(sectionId)) {
            updatedData[i].widgets = sections[sectionId];
         }
      }
      let data = {
         report_name: reportName,
         status: status,
         status_change: 1,
         status_updated_by: UserId.value,
         template: {
            templateName: reportName,
            sections: updatedData,
         },
      };
      if (reportID == null) {
         try {
            let token = APIS_ENDPOINT.bearerToken || userToken.value;
            let url = `${BASE_URL}/gusd/report`;
            //Adding WidgetType = 2 if dont have
            for (let i = 2; i < data.template.sections.length; i++) {
               const section = data.template.sections[i];
               for (let j = 0; j < section.widgets.length; j++) {
                  section.widgets[j].widget_type = 2;
               }
            }
            const response = await POST_DATA(url, data, token);
            setReportID(String(response.report.id));
            toast.current.show({
               severity: "success",
               summary: `Report ${status == "Progress" ? "saved" : status}`,
               life: 3000,
            });
            if (status == "Publish") { navigate(`/${DASHBOARD}`); }
         } catch (error) {
            toast.current.show({
               severity: "error",
               summary: error?.message,
               life: 3000,
            });
         }
      } else {
         try {
            let token = APIS_ENDPOINT.bearerToken || userToken.value;
            let url = `${BASE_URL}/gusd/report/${reportID}`;

            //Adding WidgetType = 2 if dont have
            for (let i = 2; i < data.template.sections.length; i++) {
               const section = data.template.sections[i];
               for (let j = 0; j < section.widgets.length; j++) {
                  section.widgets[j].widget_type = 2;
               }
            }

            if (UserRole.value === 2) {
               for (let j = 0; j < data.template.sections.length; j++) {
                  if (data.template.sections[j].id !== 1 && data.template.sections[j].id !== 2) {
                     for (let k = 0; k < data.template.sections[j].widgets.length; k++) {
                        if (!data.template.sections[j].widgets[k].status && !data.template.sections[j].widgets[k].status_updated_by) {
                           data.template.sections[j].widgets[k].status = 1;
                           data.template.sections[j].widgets[k].status_updated_by = UserId.value;
                        }
                     }
                  }
               }
            }

            //  console.log("updatedSection", updatedSection)


            const response = await PUT_DATA(url, data, token);
            setReportID(String(response.template.report_id));
            toast.current.show({
               severity: "success",
               summary: `Report ${status == "Progress" ? "saved" : status}`,
               life: 3000,
            });
            if (status == "Publish") {
               navigate(`/${DASHBOARD}`);
            }
         } catch (error) {
            toast.current.show({
               severity: "error",
               summary: error?.message,
               life: 3000,
            });
         }
      }
      reportData();
   };
   //END SAVE DATA INTO DB

   const handleEditSectionData = () => {
      const sections = {
         Opening: { state: opening, setState: setOpenings },
         "Acknowledgements and Recognition": {
            state: acknowledgmentsAndRecordings,
            setState: setAcknowledgmentsAndRecordings,
         },
         Presentation: { state: presentation, setState: setPresentation },
         "Student Board Member Report": {
            state: studentAndMember,
            setState: setStudentAndMember,
         },
         "Communications from the Public": {
            state: publicCommunication,
            setState: setPublicCommunication,
         },
         "Information": {
            state: informationSection,
            setState: setInformationSection,
         },
         "Action": { state: actionSection, setState: setActionSection },
         "Consent": { state: consent, setState: setConsentSection },
         "Reports and Correspondence": {
            state: reportsAndCorrespondence,
            setState: setReportsAndCorrespondence,
         },
         Adjournment: { state: adjournment, setState: setAdjournment },
      };

      const { state, setState } = sections[selectedItem];

      console.log("detailedDescription", detailedDescription);

      if (previewReportTitle != "") {
         const formData = {
            id: editWidgetId,
            name: previewReportTitle,
            value: previewReportTitle,
            widget_type: 2,
            WidgetAttribute: {
               attributetitle: previewReportTitle,
               shortDescription: shortDescription,
               detailedDescription: (detailedDescription).replace(/ /g, "&nbsp;"),
               attachment: attachments,
               fileContent: attachments.fileContent,
               to: to,
               from: from,
               preparedBy: preparedBy,
               submittedBy: submittedBy,
               subject: subject,
               selectBoardProperties: boardPriorities,
            },
         };

         const existingIndex = state.findIndex((item) => item.id === formData.id);

         if (existingIndex !== -1) {
            // Update the existing item with the same ID
            const updatedState = [...state];
            updatedState[existingIndex] = formData;
            setState(updatedState);
         } else {
            setState((current) => [...current, formData]);
         }
         setPreviewReportTitle("");
         setShortDescription("");
         setDetailedDescription("");
         setAttachments("");
         handleVisibleFrom();
         setEditWidgetId();
         setEditWidget(false);
      } else {
         toast.current.show({
            severity: "error",
            summary: "Please Fill Title field",
            life: 3000,
         });
      }
   };

   const [visibleCoverPage, setVisibleCoverPage] = useState(false);
   const handleVisibleCoverPage = () => {
      setVisibleCoverPage((visibleCoverPage) => !visibleCoverPage);
   };

   const handleRemoveImageAPI = async (key) => {
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = `${BASE_URL}/gusd/file`;
      let data = { key: key };
      try {
         let response = await fetch(url, {
            method: "DELETE",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
         });

         // Check if the request was successful
         if (response.ok) {
            setAttachments(null);
         } else {
            console.log("Request failed with status:", response.status);
         }
      } catch (error) {
         console.log("Error:", error);
      }
   };

   const [pdfViewer, setPdfViewer] = useState(false);
   const [reportComments, setReportComments] = useState([]);
   const handlePdfViewer = async () => {
      // Check comments
      let url = BASE_URL + APIS_ENDPOINT.reportComments + reportID;
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let reportCommentsList = await GAT_DATA(url, token);
      if (reportCommentsList.length) { setReportComments(reportCommentsList) } else { setReportComments([]) }
      setPdfViewer((pdfViewer) => !pdfViewer);
   };

   const [isLoading, setLoading] = useState(false);

   function checkTemplateTime(template) {
      for (let i = 0; i < template.template.usedSection.length; i++) {
         if (template.template.usedSection[i].ReportTemplateSection.time !== "00:00" && template.template.usedSection[i].ReportTemplateSection.time !== null && template.template.usedSection[i].ReportTemplateSection.time !== undefined) {
            return true;
         }
      }
      return false;
   }

   const [reportStatusId, setReportStatusId] = useState(0);
   const [reportCreatedId, setReportCreatedId] = useState("");
   const reportData = async () => {
      setLoading(true);
      try {
         let isObjectEmpty = (objectName) => { return Object.keys(objectName).length === 0 };
         let checkObjectNull = isObjectEmpty(reportCurrentSectionDetails);
         if (!checkObjectNull) {


            // showApproveButtonForReportBuilder === 1 && showApprovedLabel === 1 && isCreateReport === 0 && isOpeningApproved === 1
            console.log("showApproveButtonForReportBuilder-->1", showApproveButtonForReportBuilder)
            console.log("showApprovedLabel-->1", showApprovedLabel)
            console.log("isCreateReport-->0", isCreateReport)
            console.log("isOpeningApproved-->1", isOpeningApproved)

            if(showApproveButtonForReportBuilder === 1 && showApprovedLabel === 1 && isCreateReport === 0 && isOpeningApproved === 1){
               console.log("success");
            }
            else{
               console.log("failed");
            }

            let url = `${BASE_URL}/gusd/report/${reportCurrentSectionDetails.id ?? reportCurrentData?.id}`;
            let token = APIS_ENDPOINT.bearerToken || userToken.value;
            let reportData = await GAT_DATA(url, token);
            console.log("reportData",reportData.status)
            if(reportData.status === "Send For Approval"){
               setShowReportSendForApproval(true);
            }
            if (reportData) { setIsDisabled(true) }
            setReportCreatedId(reportData.createdById)
            if (reportData.status_change !== false) { setReportStatusId(reportData.status_change) } else { setReportStatusId(false) }
            sectionData = reportData.template.usedSection;
            let hasDifferentTime = checkTemplateTime(reportData);
            setLoading(false);
            setShowSetupTable(hasDifferentTime)
            setReportName(reportData.name);
            //Check if section 1 & 2 Not approve and make it approve
            let getUnApprovedSections = reportData?.template?.usedSection.filter((a) => (a.ReportTemplateSection.status == false && a.id == 1) || (a.ReportTemplateSection.status == false && a.id == 2))


            // For Report Builder we will take 0 and for others we will take 2 index
            var userRoleForChecking = UserRole.value === 2 ? 0 : 2;
            let isOpeningApprovedStatus = reportData?.template?.usedSection[userRoleForChecking]?.ReportTemplateSection?.status;
            setIsOpeningApproved(isOpeningApprovedStatus);

            let getUnApprovedSectionsTest = reportData?.template?.usedSection.filter((a) => (a.id !== 1) && (a.id !== 2))
            let checkAllSectionsAreApprovedTest = getUnApprovedSectionsTest.every(a => a.wedgets.every((st) => st.status == 1))

            setAllWidgetsApproved(checkAllSectionsAreApprovedTest);

            let checkAllSectionsAreApproved = getUnApprovedSections.every(a => a.wedgets.every((st) => st.status == 0))
            if (checkAllSectionsAreApproved) {
               for (var i = 0; i < getUnApprovedSections.length; i++) {
                  let data = {
                     "reportId": reportID,
                     "sectionId": getUnApprovedSections[i].id,
                     "userId": UserId.value,
                     "status": 1,
                     "commentsData": []
                  }
                  let token = APIS_ENDPOINT.bearerToken || userToken.value;
                  let url = BASE_URL + APIS_ENDPOINT.sectionApproveDeny;
                  await POST_DATA(url, data, token);
               }
            }

            const updatedArray = reportData?.template.usedSection.map((obj) => {
               // if (obj.id === item.id) { return { ...obj, time: obj.ReportTemplateSection.time}}
               return obj;
            });
            setCurrentColumns(updatedArray ?? "");
            if (reportData?.template?.usedSection[0]?.ReportTemplateSection) { setIsSectionApproved(reportData?.template?.usedSection[0]?.ReportTemplateSection.status) }
            if (reportData?.template?.usedSection) {
               let getOtherSections = reportData?.template?.usedSection.every((s) => s.ReportTemplateSection.status)

               if (getOtherSections === true) { setAllSectionApproved(true) } else { setAllSectionApproved(false) }
            }
            const sections = {
               "Opening": { state: opening, setState: setOpenings },
               "Acknowledgements and Recognition": { state: acknowledgmentsAndRecordings, setState: setAcknowledgmentsAndRecordings },
               "Presentation": { state: presentation, setState: setPresentation },
               "Student Board Member Report": { state: studentAndMember, setState: setStudentAndMember },
               "Communications from the Public": { state: publicCommunication, setState: setPublicCommunication },
               "Information": { state: informationSection, setState: setInformationSection },
               "Action": { state: actionSection, setState: setActionSection },
               "Consent": { state: consent, setState: setConsentSection },
               "Reports and Correspondence": { state: reportsAndCorrespondence, setState: setReportsAndCorrespondence },
               "Adjournment": { state: adjournment, setState: setAdjournment },
            };
            setReportID(reportData.id);
            for (let i = 0; i < reportData?.template?.usedSection.length; i++) {
               const sectionId = reportData?.template?.usedSection[i].id;
               if (sectionId > 2) {
                  // eslint-disable-next-line no-unused-vars
                  const { state, setState } = sections[reportData?.template?.usedSection[i].name];
                  setState(reportData?.template?.usedSection[i].wedgets);
               } else if (sectionId == 1) {
                  let tempAC = reportData?.template?.usedSection[i];
                  if (tempAC) {
                     let getName = tempAC.wedgets.filter((e) => e.name == "Name"); setNameAC(getName[0].widgetValues[0].value);
                     let getAddress = tempAC.wedgets.filter((e) => e.name == "Address"); setAddressAC(getAddress[0].widgetValues[0].value);
                     let getTitle = tempAC.wedgets.filter((e) => e.name == "Title"); setTitleAC(getTitle[0].widgetValues[0].value);
                     let getMeetingType = tempAC.wedgets.filter((e) => e.name == "Meeting Type"); setMeetingTypeAC(getMeetingType[0].widgetValues[0].value);
                     let getMeetingDate = tempAC.wedgets.filter((e) => e.name == "Date"); setDateAC(getMeetingDate[0].widgetValues[0].value);
                     let getMeetingNo = tempAC.wedgets.filter((e) => e.name == "Meeting Number"); setMeetingNumberAC(getMeetingNo[0].widgetValues[0].value);
                  }
               } else if (sectionId == 2) {

                  let tempA = reportData?.template?.usedSection[i].wedgets;
                  for (let A = 0; A < tempA.length; A++) {
                     if (tempA[A].name == "Location") {
                        setNameAndAddressAgenda(tempA[A].widgetValues[0].value);
                     } else if (tempA[A].name === "Name And Address") {
                        setTitleA(tempA[A].widgetValues[0].value);
                     } else if (tempA[A].name == "Title") {
                        let quotesarray = JSON.parse(tempA[A].widgetValues[0].value);
                        setOptionStatevalue(quotesarray.length > 0 ? quotesarray : ['']);
                     }
                  }
               }
            }



            //get Sections based on user
            var getSectionsURL = BASE_URL + APIS_ENDPOINT.getUserSectionPermissions + "/" + UserId.value;
            let getUserSections = await GAT_DATA(getSectionsURL, token);
            if (getUserSections.length) {
               setHideSectionWiseApprove(true)
               setSelectedItem(reportData?.template?.usedSection[getUserSections[0].section_id - 1]?.name);
               // setSelectedItemSectionId(getUserSections[getUserSections[0].section_id - 1].section_id);
               setSelectedItemSectionId(getUserSections[getUserSections[0].section_id - 3].section_id);
            }
         } else {
            setIsCreateReport(1);
            setLoading(false);
         }
      } catch (error) {
         setLoading(false);
      }
   };

   // Check if the item is expired and remove it from local storage
   function checkItemExpiration() {
      const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
      const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
      if (item && new Date().getTime() > item?.expirationTime) {
         localStorage.removeItem('GUSD-user-token');
         window.location.reload();
         window.location = '/'
      }

      if (itemGoogle && new Date().getTime() > item?.expirationTime) {
         localStorage.removeItem('GUSD-user-Google-token');
         window.location.reload();
         window.location = '/'
      }
   }

   setInterval(checkItemExpiration, 600000);

   useEffect(() => {
      reportData();
   }, []);
   const addAdditionalQuotes = () => {
      setOptionStatevalue([...optionStatevalue, '']);
   }

   const setHandlerQuoteA = ({ e, i }) => {
      let namevalue = e.target.value;
      let CurrentElementData = [...optionStatevalue[i]];
      let ValueUpdatekey = [...CurrentElementData, namevalue];
      let arr = [...optionStatevalue];
      arr[i] = ValueUpdatekey[ValueUpdatekey.length - 1];
      // setFilesArray(attachmentObject)
      setOptionStatevalue(arr);
   }
   const stateDeletHandle = (i) => {
      if (optionStatevalue.length > 1) {
         let data = [...optionStatevalue];
         data.splice(i, 1)
         setOptionStatevalue(data);
      }
   }
   //-------------- Change Status of Section --------------
   const changeStatusSection = (statusId) => {
      let status = ""
      if (statusId == 1) { status = "Approve" }
      else if (statusId == 2) { status = "Deny" }
      else if (statusId == 3) { status = "Send for approval" }

      confirmDialog({
         message: 'Do you want to ' + status + ' this record?',
         header: 'Confirmation',
         icon: 'pi pi-info-circle',
         acceptClassName: 'p-button p-component p-confirm-dialog-accept',
         accept: () => ChangeStatusAPI(statusId)
      });
   }

   const ChangeStatusAPI = async (statusId) => {
      let data = {
         "reportId": reportID,
         "sectionId": selectedItemSectionId,
         "userId": UserId.value,
         "status": statusId,
         "commentsData": []
      }
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = BASE_URL + APIS_ENDPOINT.sectionApproveDeny;
      const response = await POST_DATA(url, data, token);
      if (response) {
         setShowApproveButtonForReportBuilder(1)
         // let url = `${BASE_URL}/gusd/report/${reportCurrentSectionDetails.id ?? reportCurrentData?.id}`;÷
         reportData()
      }
   }
   //-------------- Change Status of Section --------------


   //-------------- Change Status of Report --------------
   const [pdfViewerForDeny, setPdfViewerForDeny] = useState(false);
   const changeStatusReport = (statusId, comments) => {
      let status = ""
      if (statusId == 1) { status = "Approve" }
      else if (statusId == 2) { status = "Deny" }
      else if (statusId == 3) { status = "Send for approval" }
      if (statusId === 1 || statusId === 2 || statusId === 3) {
         confirmDialog({
            message: 'Do you want to ' + status + ' this report?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button p-component p-confirm-dialog-accept',
            accept: () => ChangeStatusAPIReport(statusId, comments)
         });
      } else {
         setPdfViewerForDeny(true)
      }
   }

   const ChangeStatusAPIReport = async (statusId, comments) => {
      let data = {
         "reportId": reportID,
         "userId": UserId.value,
         "status": statusId,
         "commentsData": comments != undefined ? comments : []
      }
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = BASE_URL + APIS_ENDPOINT.reportApproveDeny;
      const response = await POST_DATA(url, data, token);
      if (response) { navigate(`/${DASHBOARD}`); }
      //Send Email Notification for section is approved.
      let emailNotificationData = { "userId": UserId.value, "receiverId": reportCreatedId, "type": "report" }
      let sendEmailNotification = BASE_URL + APIS_ENDPOINT.sendEmailNotification + reportID;
      // await POST_DATA(sendEmailNotification, emailNotificationData, token);
   }

   //-------------- Change Status of Section --------------
   //-------------- Change Status of Widget --------------
   const [pdfViewerTitleWiseForDeny, setPdfViewerTitleWiseForDeny] = useState(false);
   const [selectedWidget, setSelectedWidget] = useState([]);
   const changeStatusWidget = (widget, statusId) => {
      setSelectedWidget(widget)
      if (statusId == 2) {
         setSelectedWidgetId(widget.id)
         setPdfViewerTitleWiseForDeny(true)
      } else {
         confirmDialog({
            message: 'Do you want to send for approval?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button p-component p-confirm-dialog-accept',
            accept: () => ChangeStatusAPIWidget(widget, statusId)
         });
      }
   }
   const denyWidget = async (statusId, comments) => {
      setPdfViewerTitleWiseForDeny(false)
      let data = {
         "sectionId": selectedWidget.id,
         "widgetId": selectedWidget.widget_id,
         "widgetAttributeId": selectedWidget.WidgetAttribute.id,
         "userId": UserId.value,
         "reportId": selectedWidget.report_id,
         "receiverId": 56,
         "status": statusId,
         "commentsData": comments
      }
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = BASE_URL + APIS_ENDPOINT.widgetApproveDeny;
      const response = await POST_DATA(url, data, token);
      if (response) { reportData() }
   }
   const ChangeStatusAPIWidget = async (widget, statusId) => {
      var token = APIS_ENDPOINT.bearerToken || userToken.value;
      let data = {
         "sectionId": selectedItemSectionId,
         "widgetId": widget.WidgetAttribute.widget_id,
         "widgetAttributeId": widget.WidgetAttribute.id,
         "userId": UserId.value,
         "reportId": widget.report_id,
         "receiverId": reportCreatedId,
         "status": statusId,
         "commentsData": []
      }
      let url = BASE_URL + APIS_ENDPOINT.widgetApproveDeny;
      const response = await POST_DATA(url, data, token);
      if (response) {
         reportData()
         let reportUrl = BASE_URL + APIS_ENDPOINT.gusdreport + "/" + reportID;
         let getReportData = await GAT_DATA(reportUrl, token);
         //Check if section Not approve and widget are approved ...
         //if all widgets are approved then approve the section ...
         let getUnApprovedSections = getReportData?.template?.usedSection.filter((a) => a.ReportTemplateSection.section_id == selectedItemSectionId)
         let checkAllSectionsAreApproved = getUnApprovedSections.every(a => a.wedgets.every((st) => st.status == 1))
         if (checkAllSectionsAreApproved) {
            let sectionData = {
               "reportId": reportID,
               "sectionId": selectedItemSectionId,
               "userId": UserId.value,
               "status": 1,
               "commentsData": []
            }
            let approveSectionUrl = BASE_URL + APIS_ENDPOINT.sectionApproveDeny;
            await POST_DATA(approveSectionUrl, sectionData, token);

            //Send Email Notification for section is approved.
            let emailNotificationData = { "userId": UserId.value, "receiverId": reportCreatedId, "type": "section" }
            let sendEmailNotification = BASE_URL + APIS_ENDPOINT.sendEmailNotification + reportID;
            // await POST_DATA(sendEmailNotification, emailNotificationData, token);
         }
      }
   }
   //-------------- Change Status of Widget --------------


   // --------------- Change Section  ---------------
   const [changeSectionList, setChangeSectionList] = useState([]);
   const [selectedWidgetToChangeSection, setSelectedWidgetToChangeSection] = useState([]);
   const [selectedSectionId, setSelectedSectionId] = useState("");

   const openChangeSection = (id) => {
      let _columnsData = [...currentColumns]
      let getSections = _columnsData.map((item) => ({
         "code": item.id,
         "name": item.name,
         "section_id": item.section_id,
      }));
      setSelectedSectionId(id)
      const filteredData = getSections.filter((item) => item.section_id !== id && item.section_id !== 1 && item.section_id !== 2);
      setChangeSectionList(filteredData)
      setShowDialog(true)
   }

   const selectSectionAndMoveContent = async (e) => {
      setShowDialog(false)
      let data = {
         "reportId": selectedWidgetToChangeSection.report_id,
         "fromSectionId": selectedSectionId,
         "toSectionId": e.code,
         "widgetIds": [selectedWidgetToChangeSection.widget_id]
      }
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let url = BASE_URL + APIS_ENDPOINT.moveSectionWidgets;
      const response = await POST_DATA(url, data, token);
      if (response) {
         toast.current.show({
            severity: "success",
            summary: "Section changed successfully.",
            life: 3000,
         }); reportData()
      }
   }
   // --------------- Change Section  ---------------

   // --------------- Title Wise Preview  ---------------
   const [pdfViewerTitleWise, setPdfViewerTitleWise] = useState(false);
   const [selectedWidgetId, setSelectedWidgetId] = useState("");
   const [selectedWidgetComments, setSelectedWidgetComments] = useState([]);
   var previewTitleReport = async (widget) => {
      setLoading(true)
      // Check comments
      let url = BASE_URL + APIS_ENDPOINT.widgetComments + widget.id;
      let token = APIS_ENDPOINT.bearerToken || userToken.value;
      let widgetCommentsList = await GAT_DATA(url, token);
      if (widgetCommentsList.length) { setSelectedWidgetComments(widgetCommentsList) } else { setSelectedWidgetComments([]) }
      setSelectedWidgetId(widget.id)
      setPdfViewerTitleWise(true)
      setLoading(false)
   }

   // --------------- Title Wise Preview  ---------------

   // const downloadReport = async () => {
   //    let url = "https://reportbuilder.hexalytics.in/gusd/report/download/" + reportID;
   //    fetch(decodeURI(url)).then((response) => {
   //       response.blob().then((blob) => {
   //          const fileURL = window.URL.createObjectURL(blob)
   //          let alink = document.createElement('a')
   //          alink.href = fileURL
   //          alink.download = reportName
   //          alink.click()
   //       })
   //    })
   // }

   // --------------- Title Wise Preview  ---------------
   const downloadReport = async () => {
      setLoading(true);
      // let url = "https://reportbuilder.hexalytics.in/gusd/report/download/" + reportID;
      let url = "https://gusd-reportbuilder.hexalytics.in/gusd/report/download/" + reportID;
      fetch(decodeURI(url)).then((response) => {
         response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = reportName
            alink.click()
            alink.addEventListener('click', () => {
            });
            setLoading(false);
         })
      })
   }

   return (
      <Container>
         {isLoading ? (
            <div className="loading__">
               <ProgressSpinner />
            </div>
         ) : null}
         <div className="report-section-container-grey">
            <div className="top-container">
               <div className="">
                  <p className="report-name">
                     <BsArrowLeft onClick={() => navigate(`/${PageNames.DASHBOARD}`)} /> Report Name
                  </p>
                  {reportNameTitle ? (
                     <div style={{ display: "flex" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Control className="editfield" type="text" value={reportName} onChange={(e) => setReportName(e.target.value)} />
                        </Form.Group>
                        <Edit size={30} style={{ padding: "5px" }} onClick={handleReportNameTitle} title="Edit" />
                     </div>
                  ) : (
                     <div style={{ display: "flex" }}>
                        <p className="report-date">{reportName}</p>
                        <Edit size={30} style={{ padding: "5px" }} onClick={handleReportNameTitle} />
                     </div>
                  )}
               </div>
               <div className="group-btn">
                  {/* {
                     !hideSectionWiseApprove && !isSectionApproved &&
                     UserPermissions.value["Widget SendForApproval"] == 1 &&
                     <button type="button" className="btn approve-button" onClick={() => changeStatusSection(3)}>
                        <BsRocketTakeoffFill /> Section - Send for approval{" "}
                     </button>
                  } */}
                  {
                     <>
                        {
                           UserPermissions.value["Approve/Deny Section"] === 1 && UserRole.value === 2 && showApproveButtonForReportBuilder === 0 && isCreateReport === 0 &&
                           // UserPermissions.value["Approve/Deny Section"] === 1 && !hideSectionWiseApprove && !isSectionApproved && !allSectionApproved &&
                           // UserPermissions.value["Approve/Deny Section"] === 1 && isSectionApproved && !allSectionApproved &&
                           <button type="button" className="btn approve-button" onClick={() => changeStatusSection(1)} title="Approve">
                              <FiCheck /> Approve
                           </button>
                        }
                     </>
                  }
                  {
                     UserPermissions.value["Report Preview"] === 1 &&
                     <button type="button" className="btn preview-button" onClick={() => handlePdfViewer()} disabled={reportID == null ? true : false} title="Preview">
                        <AiOutlineEye /> Preview
                     </button>
                  }

                  {
                     UserPermissions.value["Build Report"] == 1 && reportStatusId !== 3 &&
                     <button type="button" className="btn save-button" onClick={() => handleValidation({ status: "Progress" })} title="Save Report">
                        <RiSave2Fill /> Save Report
                     </button>
                  }

                  {/* OTHER STATUS */}
                  {
                     showApproveButtonForReportBuilder === 1 && showApprovedLabel === 1 && isCreateReport === 0 && isOpeningApproved === 1 && !showReportSendForApproval &&
                     // isSectionApproved == 1 && reportStatusId !== 3 && !allSectionApproved &&
                     <div className="btn approve-button" title="Approved">
                        Approved{" "}
                     </div>
                  }

                  {
                     isSectionApproved == 3 && UserPermissions.value["Approve/Deny Section"] !== 1 &&
                     <button type="button" className="btn approve-button" title="Waiting For Approval">
                        Waiting For Approval
                     </button>
                  }

                  {
                     // UserPermissions.value["Report SendForApproval"] == 1 && reportStatusId !== 3 && allSectionApproved &&
                     UserPermissions.value["Report SendForApproval"] == 1 && allWidgetsApproved && allSectionApproved && !showReportSendForApproval &&
                     <button type="button" className="btn approve-button" onClick={() => changeStatusReport(3)} title=" Report - Send for approval">
                        <BsRocketTakeoffFill /> Report - Send for approval
                     </button>
                  }

                  {
                     // UserPermissions.value["Report SendForApproval"] == 1 && reportStatusId !== 3 && allSectionApproved &&
                     UserPermissions.value["Report SendForApproval"] == 1 && allWidgetsApproved && allSectionApproved && showReportSendForApproval &&
                     <button type="button" className="btn preview-button" title=" Report - Pending for approval">
                        <BsRocketTakeoffFill /> Report - Pending for approval
                     </button>
                  }

                  {
                     allSectionApproved && reportStatusId == 3 &&
                     <>
                        {
                           UserPermissions.value["Approve Master Report"] === 1 &&
                           <button type="button" className="btn reject-button" onClick={() => changeStatusReport(0)} title="Deny Report">
                              <FcCancel /> Deny Report
                           </button>
                        }
                        {
                           UserPermissions.value["Approve Master Report"] === 1 &&
                           <button type="button" className="btn approve-button" onClick={() => changeStatusReport(1)} title="Approve Report">
                              <FiCheck /> Approve Report
                           </button>
                        }
                     </>
                  }
                  {
                     UserPermissions.value["Report Preview"] === 1 && reportID &&
                     <div onClick={() => downloadReport(reportID)} className="d-flex justify-content-center align-items-center">
                        <i className="pi pi-download" style={{ fontSize: "1.9rem" }}></i>
                        {isLoading ? (
                           <div className="loading__">
                              <ProgressSpinner />
                           </div>
                        ) : null}
                     </div>
                  }
               </div>
            </div>
            <div className="sub-container-report">
               <div className="report-section">
                  <div className="report-section-header">
                     <h6>Report Section</h6>
                     {/* <div>
                <button className='btn add-btn btn-add-sm'>Add <FiPlus /></button>
              </div> */}
                  </div>
                  <div style={{ display: "block" }}>
                     <DragDropContext onDragEnd={handleDragEnd}>
                        <table className="dragDrop-table">
                           <Droppable droppableId="columns">
                              {(provided) => (
                                 <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                    {currentColumns?.map((item, index) => {
                                       // console.log(item,"Item")
                                       return (
                                          <Draggable key={index} draggableId={index.toString()} index={index}>
                                             {(provided) => (
                                                <>
                                                   {
                                                      UserPermissions.value[item.name] === 1 &&
                                                      <div {...provided.draggableProps} ref={provided.innerRef} onClick={() => handleSelect(item)} className={`list-of-items ${selectedItem === item.name ? "selected" : ""}`}>
                                                         <div style={{ display: "flex" }}>
                                                            <div {...provided.dragHandleProps} className="handle">
                                                               <RxDragHandleDots2 style={{ color: "black" }} title="Move" />
                                                            </div>
                                                            <div> {item.name} </div>
                                                         </div>
                                                         <div className="delete">
                                                            {reportSectionDetails.id ?
                                                               <RiDeleteBinLine style={{ color: "red" }} onClick={() => handleDelete(index)} title="Delete" />
                                                               : null}
                                                         </div>
                                                      </div>
                                                   }
                                                </>

                                             )}
                                          </Draggable>

                                       );
                                    })}
                                    {provided.placeholder}
                                 </tbody>
                              )}
                           </Droppable>
                        </table>
                     </DragDropContext>
                  </div>
               </div>
               <div className="report-details">
                  <div className="report-section-header">
                     <div>{selectedItem}</div>
                  </div>
                  <div className="report-details-section">
                     {(() => {
                        switch (selectedItem) {
                           case "Agenda":
                              return (
                                 <div className="agenda-container">
                                    <Form>
                                       <div className="agenda_cover_container">
                                          <div className="agenda_cover_items_one">
                                             {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>
                                                   Name and Address <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <div className="Card">
                                                   <Editor readOnly={isDisabled ? true : false} value={nameAndAddressAgenda} onTextChange={(e) => setNameAndAddressAgenda(e.htmlValue)} style={{ height: "150px" }} />
                                                </div>
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Title <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control disabled={isDisabled ? true : false} value={titleA} onChange={handleTitleA} type="text" placeholder="" />
                                             </Form.Group> */}
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Location<span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={titleA} onChange={handleTitleA} type="text" placeholder="" />
                                             </Form.Group>
                                             {optionStatevalue.map((item, i) => (
                                                <>
                                                   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                      <Form.Label>Quote <span style={{ color: "red" }}>*</span></Form.Label>
                                                      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                         {/* quoteA */}
                                                         <Form.Control value={item} onChange={(e) => setHandlerQuoteA({ e, i })} type="text" placeholder="" />
                                                         <div onClick={() => stateDeletHandle(i)}>
                                                            <Trash style={{ width: "18px", color: "gray" }} />
                                                         </div>
                                                      </div>
                                                   </Form.Group>
                                                </>
                                             ))}
                                             <div className="btn btn-text d-flex px-0 justify-content-end" onClick={() => addAdditionalQuotes()}>Add additional quotes <span className="btn-icon"><Add /></span></div>
                                          </div>

                                          <div className="agenda_cover_items_two">
                                             {/* <div className="agenda_cover_box">
                                                <div className="agenda_cover_box_item_one">
                                                   <Form.Label>Date</Form.Label>
                                                   <Form.Control value={dateA} onChange={handleDateA} type="date" placeholder="" />
                                                </div>
                                                <div className="agenda_cover_box_item_two">
                                                   <Form.Label>Meeting Number</Form.Label>
                                                   <Form.Control value={meetingNumberA} onChange={handleMeetingNumberA} type="text" placeholder="" />
                                                </div>
                                             </div> */}
                                             <div >
                                                {/* (showSetupTable &&  */}
                                                {showSetupTable ? (
                                                   <>
                                                      <table className="table table-bordered rounded-table table-striped my-4">
                                                         <tbody>
                                                            {currentColumns?.map((item) => {
                                                               if (item.name === "Agenda" || item.name === "Agenda Cover") {
                                                                  return null; // Skip rendering this item
                                                               }
                                                               return (
                                                                  <tr key={item.id}>
                                                                     <td>{item.name}</td>
                                                                     <td>{item.ReportTemplateSection == undefined ? item.time : item.ReportTemplateSection.time}</td>
                                                                  </tr>
                                                               );
                                                            })}
                                                         </tbody>
                                                      </table>
                                                      <Button className="btn btn-dotted my-3" onClick={() => setSetupAgenda(true)}> <AiOutlineEdit /> edit setup</Button>
                                                   </>
                                                ) : (
                                                   <button type="button" className="btn btn-dotted my-3" onClick={() => handleSetupAgenda()}>
                                                      Setup agenda <Add style={{ marginLeft: "15px" }} />
                                                   </button>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    </Form>
                                 </div>
                              );
                           case "Agenda Cover":
                              return (
                                 <div className="agenda-container">
                                    <Form>
                                       <div className="agenda_cover_container">
                                          <div className="agenda_cover_items_one">
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Name {!isDisabled && <span style={{ color: "red" }}>*</span>}
                                                </Form.Label>
                                                <Form.Control value={nameAC} disabled={isDisabled ? true : false} onChange={(e) => setNameAC(e.target.value)} type="text" placeholder="" />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Address {!isDisabled && <span style={{ color: "red" }}>*</span>}
                                                </Form.Label>
                                                <div className="Card">
                                                   <Editor value={addressAC} readOnly={isDisabled ? true : false} onTextChange={(e) => setAddressAC(e.htmlValue)} style={{ height: "150px" }} />
                                                </div>
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Title {!isDisabled && <span style={{ color: "red" }}>*</span>}
                                                </Form.Label>
                                                <Form.Control value={titleAC} disabled={isDisabled ? true : false} onChange={handleTitle} type="text" placeholder="" />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Meeting type <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={meetingTypeAC} onChange={handleMeetingTypeAC} type="text" placeholder="" />
                                             </Form.Group>
                                          </div>
                                          <div className="agenda_cover_items_two">
                                             <div className="agenda_cover_box">
                                                <div className="agenda_cover_box_item_one">
                                                   <Form.Label>
                                                      Date <span style={{ color: "red" }}>*</span>
                                                   </Form.Label>
                                                   <Form.Control value={dateAC} onChange={handleDateAC} type="date" placeholder="" />
                                                </div>
                                                <div className="agenda_cover_box_item_two">
                                                   <Form.Label>
                                                      Meeting Number <span style={{ color: "red" }}>*</span>
                                                   </Form.Label>
                                                   <Form.Control value={meetingNumberAC} onChange={handleMeetingNumberAC} type="text" placeholder="" />
                                                </div>
                                             </div>
                                             {/* <div className="agenda_image_select">
                                                <Form.Label>Image cover</Form.Label>
                                                <Form.Control className="file-dropzone" onChange={handleImageCoverAC} type="file" placeholder="" />
                                                {imageCoverLocalAC && (
                                                   <div>
                                                      <img src={imageCoverLocalAC} alt="Selected cover" width="200" />
                                                   </div>
                                                )}
                                             </div> */}
                                          </div>
                                       </div>
                                    </Form>
                                 </div>
                              );
                           case "Opening":
                              return (
                                 <>
                                    <div className="opening-container" style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragOpenings}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {opening?.map((item, index) => {
                                                         return (
                                                            <>
                                                               {setStatus(item.status)}
                                                               <Draggable key={index} draggableId={index.toString()} index={index}>
                                                                  {(provided) => (
                                                                     <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                        <div className="agenda_report_title">
                                                                           <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                              <TbGripHorizontal />
                                                                           </span>
                                                                           <span className="agenda_number_color">{index + 1}</span>
                                                                           <span className="agenda_name_color">{item.name}</span>
                                                                        </div>
                                                                        <div style={{ display: "flex", gap: "15px" }}>
                                                                           {
                                                                              item.id != index + 1 &&
                                                                              <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                                 <AiOutlineEye />
                                                                              </button>
                                                                           }
                                                                           {
                                                                              item.id != index + 1 &&
                                                                              UserPermissions.value["Change Section"] == 1 &&
                                                                              <button type="button" className="agenda_delete_btn">
                                                                                 <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                              </button>
                                                                           }

                                                                           {/* ======================== Widget SendFor Approve ======================== */}
                                                                           {
                                                                              hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                              UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                              <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                                 Send for approval
                                                                              </button>
                                                                           }
                                                                           {
                                                                              hideSectionWiseApprove && item.status === 3 &&
                                                                              UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                              <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                                 Pending approval
                                                                              </Button>
                                                                           }
                                                                           {
                                                                              hideSectionWiseApprove && item.status === 1 &&
                                                                              <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                                 Approved
                                                                              </Button>
                                                                           }
                                                                           {/*  ======================== Widget Approve / Deny ======================== */}
                                                                           {
                                                                              item.status === 3 &&
                                                                              UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                              UserRole.value !== 2 &&
                                                                              <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                           }
                                                                           {
                                                                              item.status === 3 &&
                                                                              UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                              UserRole.value !== 2 &&
                                                                              <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                           }
                                                                           {
                                                                              item.status == 2 &&
                                                                              <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                           }
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                              <AiOutlineEdit /> Edit
                                                                           </button>

                                                                           <button type="button" className="agenda_delete_btn" onClick={() => setOpenings((current) => current.filter((data) => data.id !== item.id))}>
                                                                              <RiDeleteBinLine />
                                                                           </button>
                                                                        </div>
                                                                     </div>
                                                                  )}
                                                               </Draggable>
                                                            </>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom(UserRole.value, getStatus)}>
                                          {" "}
                                          Add
                                       </button>
                                    }


                                 </>
                              );
                           case "Acknowledgements and Recognition":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragAcknowledgmentsAndRecordings}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {acknowledgmentsAndRecordings?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }

                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           UserRole.value !== 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           UserRole.value !== 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setAcknowledgmentsAndRecordings((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>

                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Presentation":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragPresentation}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {presentation?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           // console.log(item,"TESTItem--->") &&
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setPresentation((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Student Board Member Report":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleStudentAndMember}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {studentAndMember?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>

                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setStudentAndMember((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Communications from the Public":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handlePublicCommunication}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {publicCommunication?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>

                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setPublicCommunication((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Information":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragInformationSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {informationSection?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>

                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setInformationSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Action":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragActionSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {actionSection?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setActionSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Consent":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragConsentSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {consent?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setConsentSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Reports and Correspondence":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragReportsAndCorrespondence}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {reportsAndCorrespondence?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <AiOutlineEye />
                                                                           </button>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setReportsAndCorrespondence((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           case "Adjournment":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragAdjournment}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {adjournment?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div style={{ display: "flex", gap: "15px" }}>
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           <button type="button" className="agenda_delete_btn" onClick={() => previewTitleReport(item)}>
                                                                              <RiDeleteBinLine />
                                                                           </button>
                                                                           // <div  className="d-flex justify-content-center align-items-center">
                                                                           //    <i className="pi pi-eye agenda_delete_btn" style={{ fontSize: "1.9rem" }}></i>
                                                                           // </div>
                                                                        }
                                                                        {
                                                                           item.id != index + 1 &&
                                                                           UserPermissions.value["Change Section"] == 1 &&
                                                                           <button type="button" className="agenda_delete_btn">
                                                                              <FiRefreshCcw size={30} onClick={() => { setSelectedWidgetToChangeSection(item); openChangeSection(selectedItemSectionId) }} />
                                                                           </button>
                                                                        }
                                                                        {/* ======================== Widget SendFor Approve ======================== */}
                                                                        {
                                                                           hideSectionWiseApprove && item.status !== 1 && item.status !== 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 && item.id != index + 1 &&
                                                                           <button type="button" className="agenda_preview_btn" onClick={() => changeStatusWidget(item, 3)}>
                                                                              Send for approval
                                                                           </button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 3 &&
                                                                           UserPermissions.value["Widget SendForApproval"] == 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Pending approval
                                                                           </Button>
                                                                        }
                                                                        {
                                                                           hideSectionWiseApprove && item.status === 1 &&
                                                                           <Button type="button" className="d-flex align-items-center rounded-pill btn-add text-[#1262d0]" color="primary" outline>
                                                                              Approved
                                                                           </Button>
                                                                        }
                                                                        {/*  ======================== Widget Approve / Deny ======================== */}
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Approve" severity="success" rounded onClick={() => changeStatusWidget(item, 1)} />
                                                                        }
                                                                        {
                                                                           item.status === 3 &&
                                                                           UserPermissions.value["Approve/Deny Section"] == 1 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Deny" severity="danger" rounded onClick={() => changeStatusWidget(item, 2)} />
                                                                        }
                                                                        {
                                                                           item.status == 2 &&
                                                                           <PrimeButton style={{ width: 120 }} label="Rejected" severity="danger" rounded disabled />
                                                                        }
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setAdjournment((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    {
                                       UserPermissions.value["Add Content to Section"] === 1 &&
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    }
                                 </>
                              );
                           default:
                              return null;
                        }
                     })()}
                  </div>

                  {/* Setup Agenda Slider */}
                  <Sidebar visible={setupAgenda} position="right" onHide={() => setSetupAgenda(false)}>
                     <div className="">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <div>
                              <h6 className="report-preview-header mb-4">Setup agenda</h6>
                           </div>
                        </div>
                     </div>
                     {/* (item?.name !== "Agenda" && item?.name !== "Agenda Cover") && */}
                     <div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                           <table className="dragDrop-table">
                              <Droppable droppableId="columns">
                                 {(provided) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                       {currentColumns?.map((item, index) => {
                                          if (item?.name === "Agenda" || item?.name === "Agenda Cover") {
                                             return null; // Skip rendering the Draggable component
                                          }

                                          const [timeDaketi, setTimeDaketi] = useState({ error: false, message: "", id: "" });
                                          const handleTime = (time, item) => {
                                             const updatedColumns = [...currentColumns];
                                             const timeExists = updatedColumns.some((obj) => (obj.ReportTemplateSection == undefined ? obj.time : obj.ReportTemplateSection.time) === time);
                                             if (timeExists) {
                                                // Handle the error or show a message indicating the time already exists
                                                setTimeDaketi({
                                                   error: true,
                                                   message: "Error: Time already exists",
                                                   id: item.id,
                                                });
                                                return;
                                             }
                                             const updatedArray = updatedColumns.map((obj) => {
                                                if (obj.id === item.id) {
                                                   return {
                                                      ...obj,
                                                      time: time,
                                                      ReportTemplateSection: {
                                                         ...obj.ReportTemplateSection,
                                                         time: time
                                                      }
                                                   };
                                                }
                                                return obj;
                                             });
                                             setCurrentColumns(updatedArray);
                                             setTimeDaketi({
                                                error: false,
                                                message: "Error: Time already exists",
                                                id: item.id,
                                             });
                                          };
                                          return (
                                             <Draggable key={index} draggableId={index.toString()} index={index}>
                                                {(provided) => (
                                                   <>
                                                      <div {...provided.draggableProps} ref={provided.innerRef} className={`list-of-items`}>
                                                         <div style={{ display: "flex" }}>
                                                            <div {...provided.dragHandleProps} className="handle">
                                                               <RxDragHandleDots2 />
                                                            </div>
                                                            <div>{item.name}</div>
                                                         </div>
                                                         <input value={item.ReportTemplateSection == undefined ? item.time : item.ReportTemplateSection.time} className="input-time" onChange={(e) => handleTime(e.target.value, item)} type="time" placeholder="00:00 pm" />
                                                      </div>
                                                      <span>{timeDaketi.id == item.id && timeDaketi.error ? <>{timeDaketi.message}</> : null}</span>
                                                   </>
                                                )}
                                             </Draggable>
                                          );
                                       })}
                                       {provided.placeholder}
                                    </tbody>
                                 )}
                              </Droppable>
                           </table>
                        </DragDropContext>
                     </div>
                     <div className="SetupAgenda-buttons">
                        <Button label="Cancel" onClick={() => setSetupAgenda(false)} className="button-text-link">
                           {" "}
                           Cancel
                        </Button>
                        <Button label="Save" style={{ width: "112px" }} onClick={handleSaveSetupAgenda} className="btn btn-primary-md">
                           {" "}
                           Save
                        </Button>
                     </div>
                  </Sidebar>

                  <Sidebar visible={visibleFrom} position="right" onHide={() => setVisibleFrom(false)}>
                     <>
                        <div className="save-content-wrapper ">
                           {
                              showSaveContent &&
                              <button type="button" className="btn btn-primary large-rounded" onClick={editWidget ? handleEditSectionData : handleSaveSectionData}>
                                 {" "}
                                 Save Content
                              </button>
                           }
                        </div>
                        <div className="">
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                 <h6 className="report-preview-header my-0">Report Preview</h6>
                                 <p className="preview-head-sub">select the section to compose your report</p>
                              </div>
                           </div>
                        </div>
                        <div>
                           <div className="collapse-parent-section">
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                 <Form.Label>Title </Form.Label>
                                 <div
                                    style={{
                                       display: "flex",
                                       justifyContent: "space-around",
                                       gap: "20px",
                                    }}
                                 >
                                    <Form.Control value={previewReportTitle} onChange={(e) => setPreviewReportTitle(e.target.value)} type="text" placeholder="" />
                                    <Button className="save-cover-btn" onClick={() => handleVisibleCoverPage()}>
                                       Save Cover Page <Edit2 size={18} />
                                    </Button>
                                 </div>
                              </Form.Group>
                              {visibleCoverPage ? (
                                 <div className="collapse-section">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>To</Form.Label>
                                       <Form.Control value={to} onChange={(e) => setTo(e.target.value)} type="text" placeholder="" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>From</Form.Label>
                                       <Form.Control value={from} onChange={(e) => seFrom(e.target.value)} type="text" placeholder="" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>Prepared By</Form.Label>
                                       <Form.Control value={preparedBy} onChange={(e) => setPreparedBy(e.target.value)} type="text" placeholder="" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>Submitted by</Form.Label>
                                       <Form.Control value={submittedBy} onChange={(e) => setsubmittedBy(e.target.value)} type="text" placeholder="" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>Subject</Form.Label>
                                       <Form.Control value={subject} onChange={(e) => setSubject(e.target.value)} type="text" placeholder="" />
                                    </Form.Group>
                                    {/* <div>
                                       <Form.Label>Select Board Priorities</Form.Label>
                                       <Form.Select className="form-control" aria-label="Default select example" onChange={(e) => setBoardPriorities(e.target.value)}>
                                          <option>Select Board Priorities</option>
                                          <option value="1">Maximize Student Achievement</option>
                                          <option value="2">Foster a Positive Culture of Learning</option>
                                          <option value="3">Ensure the Health and Safety of Students and Employees</option>
                                          <option value="4">Maintain District Financial Responsibility</option>
                                       </Form.Select>
                                    </div> */}
                                    <div className="card flex justify-content-center">
                                       <MultiSelect value={priorities} onChange={(e) => onChangePriorityDropdown(e)} options={[
                                          // { name: 'Select Board Priorities', code: 'Select Board Priorities' },
                                          { name: 'Maximize Student Achievement', code: '1' },
                                          { name: 'Foster a Positive Culture of Learning', code: '2' },
                                          { name: 'Ensure the Health and Safety of Students and Employees', code: '3' },
                                          { name: 'Maintain District Financial Responsibility', code: '4' }
                                       ]}
                                          //  <option value="1">Maximize Student Achievement</option>
                                          //  <option value="2">Foster a Positive Culture of Learning</option>
                                          //  <option value="3">Ensure the Health and Safety of Students and Employees</option>
                                          //  <option value="4">Maintain District Financial Responsibility</option>
                                          optionLabel="name"
                                          placeholder="Select Priorities" maxSelectedLabels={3} className="w-full md:w-20rem" />
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                       <Form.Label>Detailed Description</Form.Label>
                                       <Editor value={detailedDescription} onTextChange={(e) => setDetailedDescription(e.htmlValue)} style={{ height: "150px" }} />
                                    </Form.Group>
                                 </div>
                              ) : null}
                           </div>

                           <div className="collapse-parent-section">
                              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                 <Form.Label>Short Description</Form.Label>
                                 <Form.Control value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} as="textarea" rows={3} placeholder="" />
                              </Form.Group>
                              <div>
                                 <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Attachments</Form.Label>
                                    <Form.Control className="file-dropzone" accept=".docx" type="file" onChange={handleAttachments} />
                                 </Form.Group>
                                 {attachments && (
                                    <>
                                       {/* <a>{attachments.attachments}</a> */}
                                       <button onClick={() => handleRemoveImageAPI(attachments?.key)} className="btn btn-link ms-2">
                                          Clear
                                       </button>
                                    </>
                                 )}
                              </div>
                           </div>
                        </div>
                     </>

                     <>
                        <div style={{ textAlign: "center" }}>
                           {
                              !showSaveContent &&
                              <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                           }
                        </div>
                     </>

                  </Sidebar>
                  <Sidebar visible={pdfViewer} position="right" onHide={() => setPdfViewer(false)}>
                     <PDFViewer id={reportID} comments={reportComments} />
                  </Sidebar>
                  <Sidebar className="p-sidebar-full" position="right" visible={pdfViewerForDeny} onHide={() => setPdfViewerForDeny(false)}>
                     <PDFViewerForDeny id={reportID} denyReport={(a) => { setPdfViewerForDeny(false); changeStatusReport(2, a) }} />
                  </Sidebar>
                  {/* Title Wise preview */}
                  <Sidebar visible={pdfViewerTitleWise} position="right" onHide={() => setPdfViewerTitleWise(false)}>
                     <TitlePDFViewer id={selectedWidgetId} comments={selectedWidgetComments} />
                  </Sidebar>
                  {/* Title Wise DENY PROCESS */}
                  <Sidebar visible={pdfViewerTitleWiseForDeny} position="right" onHide={() => setPdfViewerTitleWiseForDeny(false)}>
                     <TitlePDFViewerForDeny id={selectedWidgetId} denyWidgetLevel={(comment) => { setPdfViewerTitleWise(false); denyWidget(2, comment) }} />
                  </Sidebar>

               </div>
            </div>
         </div>
         <Toast ref={toast} position="bottom-left" />
         <ConfirmDialog />

         <Dialog header="Select Section" visible={showDialog} position={"center"} style={{ width: '50vw' }} onHide={() => setShowDialog(false)} draggable={false} resizable={false}>
            <ListBox options={changeSectionList} onChange={(e) => selectSectionAndMoveContent(e.value)} optionLabel="name" className="w-full md:w-14rem" />
         </Dialog>
      </Container>
   );
};

export default ReportSection;
