
// export const BASE_URL = "https://reportbuilder.hexalytics.in";
export const BASE_URL = "https://gusd-reportbuilder.hexalytics.in";
let item = JSON.parse(localStorage.getItem('GUSD-user-token'));

export const APIS_ENDPOINT = {
    bearerToken: item?.value,
    userGoogleCreate: "/gusd/user/google",
    getMeetingTemplate: "/gusd/template",
    currentMonthMeeting: "/gusd/meeting/currentmonth",
    gusdreport: "/gusd/report",
    searchmeetingtitle: "/gusd/meeting/title/",
    searchmeetingId: "/gusd/meeting/id/",
    createMeeting: "/gusd/meeting/",
    dashboardCurrentReport: "/gusd/report",
    meetingPublishReport: "/gusd/meeting/id/",
    meetingReport: "/gusd/report/",
    gusdreportid: "/gusd/report/",
    meetingAttachmentReport: '/gusd/meeting/attachments/',
    reportSectionTemplate: `${BASE_URL}/gusd/template`,
    createReport: `${BASE_URL}/gusd/report`,
    uploadImageFile: '/gusd/file',
    roleList: "/gusd/role/",
    userList: "/gusd/googleuser",
    rolePermissions: "/gusd/permission/getrolespermission",
    saveRolePermissions: "/gusd/permission/saveRolesPermission",
    allSections: "/gusd/section",
    getUserSectionPermissions: "/gusd/permission/getUserSectionPermission",
    saveUserSectionPermissions: "/gusd/permission/saveUserSectionPermission",
    validateAdminPermission: "/gusd/permission/validateAdminPermission",
    sectionApproveDeny: "/gusd/section/approve-deny",
    reportApproveDeny: "/gusd/report/approve-deny",
    widgetApproveDeny: "/gusd/widgetType/approve-deny",
    userNotification: "/gusd/user/notification/",
    moveSectionWidgets: "/gusd/section/moveSectionWidgets",
    useExistingReport: "/gusd/report/useExistingTemplate",
    widgetTypePreview: "/gusd/widgetType/preview/",
    widgetComments: "/gusd/widgetType/comments/",
    reportComments: "/gusd/report/comments/",
    sendEmailNotification: "/gusd/notification/",
}
