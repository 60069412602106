import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Toast } from 'primereact/toast';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function TitlePDFViewerForDeny(props) {
  const pageContainersRefs = useRef([]);
  const toast = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [renderItems, setRenderItems] = useState(false);
  const [comments, setComments] = useState({});
  const [multipleComments, setMultipleComments] = useState([]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    let finalData = []
    for (var i = 1; i <= numPages; i++) {
      let data = { index: i, comments: [] }
      finalData.push(data)

      if (i === numPages) {
        setMultipleComments(finalData)
      }
    }
    setNumPages(numPages);
    setRenderItems(true)
  };

  const handleCommentChange = (event, pageNumber) => {
    const { value } = event.target;
    setComments((prevComments) => ({
      ...prevComments,
      [pageNumber]: value,
    }));
  };

  const saveComments = (pageNumber) => {
    const objectToUpdate = multipleComments.find(item => item.index === pageNumber);
    if (objectToUpdate) {
      setComments((prevComments) => ({
        ...prevComments,
        [pageNumber]: comments[pageNumber],
      }));
      let generateId = guidGenerator()
      let data = {
        id: generateId,
        date: new Date().toISOString(),
        comment: comments[pageNumber],
        // color: "red"
      }
      objectToUpdate.comments.push(data);
    }
    // Clear the comment for the current page
    setComments((prevComments) => ({ ...prevComments, [pageNumber]: '' }));
  }

  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  }

  useEffect(() => {
    // Scroll to the end of the page container for the respective page
    const scrollToBottom = (pageNumber) => {
      const pageContainer = pageContainersRefs.current[pageNumber - 1];
      if (pageContainer) {
        pageContainer.scrollTop = pageContainer.scrollHeight;
      }
    };

    // Whenever the comments change for a specific page, trigger the scrolling behavior
    const currentPageNumber = Object.keys(comments)[0];
    if (currentPageNumber) {
      scrollToBottom(Number(currentPageNumber));
    }
  }, [comments]);

  const renderPageWithComments = (pageNumber) => {
    return (
      <div className="flex mb-2" ref={pageContainersRefs}>
        <Page canvasBackground="#FFF" pageNumber={pageNumber} />
        <div className="relative bg-[#fff] left-[50px] p-10">
          {
            multipleComments.map((e) => {
              return (
                e.index === pageNumber &&
                <div className="h-[650px] overflow-auto">
                  {
                    e.comments.map((ab, index) => {
                      return (
                        <div key={index.toString()} className="text-center" style={{ textAlign: "right", padding: 5, color: ab.color }}>
                          {ab.comment}
                          {/* <Button onClick={() => updateComplete(ab)} size="small" label="Complete" severity="success" rounded /> */}
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }

          <div className="d-flex align-items-center">
            <textarea
              placeholder="Add a comment"
              style={{ width: "85%" }}
              className="p-3 border border-[#333]"
              value={comments[pageNumber]}
              onChange={(event) => handleCommentChange(event, pageNumber)}
            />
            {/* <button onClick={() => saveComments(pageNumber)} title="Add comment" > Add</button> */}
            <Button style={{ marginLeft: 20 }} onClick={() => saveComments(pageNumber)} >  Add</Button>
          </div>
        </div>
      </div>
    );
  };

  const bindData = () => {
    let filteredComments = multipleComments.filter(a => a.comments.length > 0)
    const modifiedData = [];

    filteredComments.forEach((item) => {
      const { index, comments } = item;
      comments.forEach((comment) => {
        modifiedData.push({
          page_no: index,
          comments: comment.comment
        });
      });
    });
    if (modifiedData.length > 0) { props.denyWidgetLevel(modifiedData) }
    else { toast.current.show({ severity: 'error', summary: 'Please enter comments.' }) }
  }
    let url = `https://gusd-reportbuilder.hexalytics.in/gusd/widgetType/preview/${props.id}`
  // let url = `https://reportbuilder.hexalytics.in/gusd/widgetType/preview/${props.id}`
  return (
    <div id="container">
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <div style={{ textAlign: "right", marginBottom: 15 }}>
          <Button style={{ backgroundColor: "#d61515" }} onClick={() => bindData()} >  DENY</Button>
        </div>
        {
          renderItems &&
          Array.from(new Array(numPages), (el, index) => (
            <div key={index}>{renderPageWithComments(index + 1)}</div>
          ))
        }
      </Document>
      <Toast ref={toast} />
    </div>
  );
}

TitlePDFViewerForDeny.propTypes = {
  id: PropTypes.string,
  denyWidgetLevel: PropTypes.func
};

export default TitlePDFViewerForDeny;